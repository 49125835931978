import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/education/education-hero.webp'
import accomplishment1 from '../../images/issues/education/education-accomplishment-1.webp'
import accomplishment2 from '../../images/issues/education/education-accomplishment-2.webp'
import accomplishment3 from '../../images/issues/education/education-accomplishment-3.webp'
import accomplishment4 from '../../images/issues/education/education-accomplishment-4.webp'


let issueDetailPageHero = 'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80'
let accomplishmentImgPlaceholder = 'http://placehold.it/502x282'

const IssuesEducationPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Issues - Education" />
    <div className="bg-white">
    <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full max-h-96 object-cover object-pos-0-40" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero}
              alt=""
            />
        </div>
        <div className="pt-10 max-w-5xl mx-auto">
          <h1 className="font-bold text-6xl">Education</h1>
          <p className="text-2xl leading-relaxed">
            As a product of Wheeler and the Providence public school system, John understands the importance of local education on the future success and well-being of Rhode Island residents. These difficult times have caused incredible uncertainty and presented innumerable challenges to the school systems, teachers, parents, and students alike. Everyone involved needs an equitable and protected educational system that provides sufficient support and structure pointed toward success. It is time to ensure that no policies reinforce socioeconomic inequality, or other issues that divide our communities and perpetuate inequity.
          </p>
          <p className="text-2xl leading-relaxed">
            To improve education, John supports Universal Pre-K, Universal Childcare, and The Pay Teachers Act, which would triple Title I-A funding and raise annual teacher salaries to at least $60,000. John also supports expanding Pell Grants, and he supports <strong>Universal Paid Family and Parental Leave</strong> and legislation to end crippling student loan debt.
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          {/*TODO: L side - bullet point, R side - image - TEXT John when I'm working on this, Sanctuary, John visiting Boston - Mass & Cass, etc.*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Advocated For Critical Investments For Our Public Schools</h3>
              <p>• Councilman John Goncalves collaborated with the community and The Avenue Concept, Rhode Island's leading public art organization, for a pilot project that has brought art to a local public school.  Read more <a href="https://council.providenceri.gov/2020/09/14/councilman-john-goncalves-partners-with-the-avenue-concept-and-the-vartan-gregorian-elementary-school-and-pto-for-a-pilot-program-to-bring-art-to-campus/" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Fighting For Our Kids During The Pandemic</h3>
              <p>• Lead sponsor on passed legislation requesting the Rhode Island Department of Education and the Providence Public School District provide remote learning best practices workshops for parents throughout the pandemic. Read more <a href="https://www.ride.ri.gov/InsideRIDE/AdditionalInformation/COVID19.aspx#43771932-resources-for-distance-learning" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Vocal Advocate On School Improvement &amp; Infrastructure Funding</h3>
              <p>• A passionate advocate and lead sponsor on legislation advocating for over $140M Bond for School Improvement Projects. Read more <a href="https://council.providenceri.gov/2020/09/08/councilman-goncalves-and-council-colleagues-introduce-several-resolutions-regarding-returning-to-school-in-providence-2/" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Passed Legislation Advocating For Educator COVID-19 Vaccine Access</h3>
              <p>• Lead sponsor on passed legislation urging Governor Raimondo and the Rhode Island Department of Health to prioritize public school educators for COVID-19 vaccine access. Read more <a href="https://providenceri.iqm2.com/Citizens/Detail_LegiFile.aspx?Frame=&MeetingID=12751&MediaPosition=&ID=30816&CssClass=" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment4}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>

          <h3 className="font-bold text-3xl mt-5">Honoring and Valuing Quality Teachers</h3>
          <p className="text-xl">Teachers form the backbone of our educational system. Their expertise, professionalism, and care are what guides Rhode Island's younger generations toward a brighter future. In order to strengthen all educational opportunities, teachers need a platform and empowerment within the system. We need to promote ongoing professional development, educational support, and all other appropriate resources teachers need during the COVID-19 pandemic and beyond.</p>

          <h3 className="font-bold text-3xl mt-5">Creating Schools With Inclusive Environments</h3>
          <p className="text-xl">Current difficulties with the pandemic and the systemic changes it causes to our educational systems do not make other issues disappear. Our communities also grapple with a history of racial injustice and inequality that threatens some students' ability to get a quality education. New initiatives must actively fight against racism segregation, and the militarization of schools in every community across Rhode Island. The school-to-prison pipeline must be disrupted, and undocumented students need the same respect and opportunities as all the rest.</p>

          <h3 className="font-bold text-3xl mt-5">Promoting Equitable Support for Mental and Behavioral Health</h3>
          <p className="text-xl">The school system exists to foster the opportunity for a quality future for every student. Alongside fighting inequalities in educational practices, we also need to focus on mental, emotional, and behavioral health. Every student deserves the support and guidance they need to thrive from professionals who have the skills and caring demeanor necessary for the job. Promoting the accessibility of nurses, guidance counselors, and mental health professionals for all students is an important part of serving them in the best way possible.</p>

          <h3 className="font-bold text-3xl mt-5">Supporting Every Student and Family Fairly</h3>
          <p className="text-xl">Rhode Island has long struggled with equitable services for nontraditional students and those who needed extra help with disabilities, behavioral problems, and English language learners. The most recent state review of our school system demonstrates this clearly. In order to move forward toward a more equitable future, we must tear down the barriers that keep these and other student populations away from access to the best curriculums, instruction assistance, and support specifically tailored to their individual needs.</p>

          <h3 className="font-bold text-3xl mt-5">Creating Healthy and Safe Educational Facilities</h3>
          <p className="text-xl">Schools and other educational facilities form the foundation of learning, development, and community. All students, involved families, teachers, and other staff deserve safe, healthy, and supportive environments. This is especially true during the pandemic. Making a strong investment in every school's future with a focus on cleanliness and sanitation, upgraded infrastructure, and improved ventilation systems will do much to create safe schools for all.</p>
            
          <h3 className="font-bold text-3xl mt-5">Developing a Strong Community Model With Proper Funding</h3>
          <p className="text-xl">Schools need to provide more than education for at-risk communities across the state. We need to push for initiatives that support them, their families, and their neighborhoods when it comes to access to secure housing and food. Rhode Island should take the opportunity to strengthen partnerships with nonprofit organizations and cultural groups to promote widespread, multi-cultural arts and social classes and programs for all students.</p>

          <h3 className="font-bold text-3xl mt-5">Offering Expanded Vocational Training Options</h3>
          <p className="text-xl">A strong educational foundation leads to improved employment opportunities, but Rhode Island's cultural and economic diversity requires more than an advanced curriculum. We recognize the need for expanded vocational training options to improve the chances of getting jobs that pay livable wages. Boosting the opportunities for job training, service trades, and other vocational industries will increase future success for students who do not attend a four-year college after graduating high school.</p>
          
          <h3 className="font-bold text-3xl mt-5">Ensuring Guaranteed Childcare and Early Education</h3>
          <p className="text-xl">Future educational success and top prospects improve when early childhood education and safe and affordable childcare is available to all families. Initiatives to improve access should include both community-based options and expanded workplace daycare.</p>
            
          <h3 className="font-bold text-3xl mt-5">Offering Safe, Accessible, and Reliable School Transportation</h3>
          <p className="text-xl">No student will benefit from improved educational opportunities if they do not have safe and accessible transportation to and from the buildings. John proposes new policies that promote reliable transport in a variety of ways. These include free transit passes for students, bike lanes and cycling safety changes, pedestrian safety initiatives, and overall infrastructure improvements to ease traffic problems. With safer streets and convenient public transportation, students have a higher chance of succeeding educationally.</p>      
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesEducationPage